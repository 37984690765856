/* eslint-disable react/destructuring-assignment */
import { useCallback, useEffect, useState } from 'react';
import { Col, Row, Spinner, Table, Button, Nav } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { StyledTh, StyledTr } from '../../../pages/OnlineStore/styles';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import ISaveInvoiceItemDto from '../../../dtos/ISaveInvoiceItemDto';
import NumericInput from '../../NumericInput';
import { ReactComponent as EraserIcon } from '../../../assets/icons/eraser-solid.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as ReactArrowRight } from '../../../assets/icons/timeline/arrow_right.svg';
import InvoiceHttpService from '../../../services/http/invoice-http';
import { InvoiceStatus } from '../../../constants/invoiceStatus';
import { formatCurrency } from '../../../utils/strings';
import { ProductAvailability } from '../../../constants/productAvailability';
import { useStructureCalculation } from '../../../contexts/structureCalculationContext';
import isFriendlyHttpError from '../../../utils/isFriendlyHttpError';
import StructureCalculationModal from '../StructureCalculation';
import useWindowSize from '../../../hooks/useWindowSize';
import {
    steps as generatorSteps,
    readyKitsSteps,
} from '../../../constants/onlineStore';
import { StyledSquare, StyledTitleNav } from '../Base/styles';
import StyledKitPowerInfo from './styles';
import ConfirmationDialog from '../../ConfirmationDialog';
import { NotificationIcon } from '../../../constants/notificationIcon';
import BaseAvailabilityBadge from '../../BaseAvailabilityBadge';

const kitPowerDecimals = 2;

const Cart = (props: any) => {
    const {
        cart,
        setCart,
        step,
        setStep,
        setGroupId,
        productType,
        setProducts,
    } = useOnlineStore();

    const [loading, setLoading] = useState(true);
    const { setStructureCalculationValues, setLines } =
        useStructureCalculation();
    const [calculationOfStructuresModal, setCalculationOfStructuresModal] =
        useState(false);
    const [interestValue, setInterestValue] = useState(0);
    const size = useWindowSize();

    const [steps, setSteps] = useState([]);

    const nextStep = (item: any) => {
        if (
            props.invoice.invoiceItems &&
            props.invoice.invoiceItems.length > 0
        ) {
            props.setStep(item.number);
        } else if (item.number > 2) {
            props.setStep(props.step);
        } else {
            props.setStep(item.number);
        }
    };

    const mutationClearCart = useMutation(
        async () => InvoiceHttpService.emptyCart(),
        {
            onError: (error: any) => {
                if (isFriendlyHttpError(error)) {
                    toast.error(error.message);

                    return;
                }

                toast.error('Ocorreu um erro ao limpar o carrinho');
            },
            onSuccess: (response) => {
                setCart(response.data);

                if (productType === 'PA' || productType === 'BF') {
                    setStep(2);
                    setGroupId('');
                }

                if (productType === 'MP') {
                    setGroupId('1001');
                    setStep(1);
                }

                setProducts([]);
                setStructureCalculationValues([]);
                setLines([]);
            },
        },
    );

    const returnCorrectInterestValue = useCallback((invoice: any) => {
        const newInterestValue = invoice.interestPercentage
            ? (invoice.totalInvoice +
                  Number(invoice.grossCommission) -
                  (invoice.discountValue || 0)) *
              (invoice.interestPercentage / 100)
            : 0;

        if (invoice.interestValue !== newInterestValue) {
            return newInterestValue;
        }

        return invoice.interestValue;
    }, []);

    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        setCart(props.invoice);

        setLoading(false);

        const generators = generatorSteps.filter(
            (item: any) => item.number !== 1 && item.number !== 4,
        );
        const kits = readyKitsSteps.filter((item: any) => item.number !== 4);

        setSteps(props.invoice.type === 'MP' ? generators : kits);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setInterestValue(returnCorrectInterestValue(props.invoice));
    }, [props.invoice, returnCorrectInterestValue]);

    return (
        <>
            {loading ? (
                <Spinner
                    style={{
                        marginLeft: '48%',
                    }}
                    animation="border"
                />
            ) : (
                <>
                    {props.invoice?.status &&
                        (props.invoice?.status === InvoiceStatus.Cart ||
                            props.invoice?.status === InvoiceStatus.Budget) && (
                            <Col>
                                <Row className="align-items-center">
                                    {props.onBudgetEditing && (
                                        <Row>
                                            {steps.map(
                                                (item: any, index: any) => (
                                                    <div key={index}>
                                                        <Nav.Item>
                                                            <Nav.Link
                                                                style={
                                                                    props.step ===
                                                                    item.number
                                                                        ? {
                                                                              color: '#F2994A',
                                                                              fontWeight: 600,
                                                                          }
                                                                        : {
                                                                              color: 'rgba(112, 112, 112, 0.3)',
                                                                          }
                                                                }
                                                                eventKey={
                                                                    item.number
                                                                }
                                                                onClick={async () => {
                                                                    nextStep(
                                                                        item,
                                                                    );
                                                                }}
                                                            >
                                                                <Row>
                                                                    <Col xl={1}>
                                                                        <StyledSquare
                                                                            background={
                                                                                props.step ===
                                                                                item.number
                                                                                    ? '#F2994A'
                                                                                    : '#fbf3e6'
                                                                            }
                                                                        >
                                                                            <h5
                                                                                style={{
                                                                                    fontSize: 38,
                                                                                    fontWeight: 600,
                                                                                    color:
                                                                                        props.step ===
                                                                                        item.number
                                                                                            ? '#F2994A'
                                                                                            : 'rgba(112, 112, 112, 0.3)',
                                                                                }}
                                                                            >
                                                                                {index +
                                                                                    1}
                                                                            </h5>
                                                                        </StyledSquare>
                                                                    </Col>

                                                                    <Col className="my-auto">
                                                                        <StyledTitleNav
                                                                            className={
                                                                                size.width <
                                                                                    1500 &&
                                                                                props
                                                                                    .invoice
                                                                                    .type ===
                                                                                    'MP'
                                                                                    ? 'mr-n4'
                                                                                    : 'ml-3'
                                                                            }
                                                                            color={
                                                                                props.step ===
                                                                                item.number
                                                                                    ? '#F2994A'
                                                                                    : 'rgba(112, 112, 112, 0.3)'
                                                                            }
                                                                        >
                                                                            {
                                                                                item.title
                                                                            }
                                                                        </StyledTitleNav>
                                                                    </Col>
                                                                    {item.number !==
                                                                        3 && (
                                                                        <ReactArrowRight
                                                                            style={{
                                                                                position:
                                                                                    'relative',
                                                                                top: '-3px',
                                                                            }}
                                                                            className={`my-auto ${
                                                                                size.width <
                                                                                    1500 &&
                                                                                props
                                                                                    .invoice
                                                                                    .type ===
                                                                                    'MP'
                                                                                    ? ''
                                                                                    : 'mr-4'
                                                                            }  `}
                                                                            fill={
                                                                                props.step ===
                                                                                item.number
                                                                                    ? '#F2994A'
                                                                                    : 'rgba(112, 112, 112, 0.3)'
                                                                            }
                                                                        />
                                                                    )}
                                                                </Row>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    </div>
                                                ),
                                            )}
                                        </Row>
                                    )}
                                    <Col className="text-right">
                                        {((cart.type === 'MP' &&
                                            props.onBudgetEditing) ||
                                            (productType === 'MP' &&
                                                step === 3)) && (
                                            <>
                                                <Button
                                                    size="lg"
                                                    variant="outline"
                                                    style={{
                                                        color: '#2F80ED',
                                                    }}
                                                    className="px-0"
                                                    onClick={() =>
                                                        setCalculationOfStructuresModal(
                                                            true,
                                                        )
                                                    }
                                                >
                                                    <EditIcon
                                                        fill="#2F80ED"
                                                        className="mr-2"
                                                        width={20}
                                                    />
                                                    Editar estrutura de fixação
                                                </Button>
                                            </>
                                        )}
                                        {!props.paymentDeliveryEditing &&
                                            !props.onBudgetEditing &&
                                            props.invoice?.status ===
                                                InvoiceStatus.Cart && (
                                                <Button
                                                    variant="outline"
                                                    size="lg"
                                                    style={{
                                                        color: '#2F80ED',
                                                    }}
                                                    className="px-0 ml-4"
                                                    onClick={() =>
                                                        setShowConfirmation(
                                                            true,
                                                        )
                                                    }
                                                >
                                                    <EraserIcon
                                                        fill="#2F80ED"
                                                        className="mr-2"
                                                        width={20}
                                                    />
                                                    Limpar Carrinho
                                                </Button>
                                            )}
                                    </Col>
                                </Row>
                            </Col>
                        )}
                    {props.invoice?.status !== InvoiceStatus.Cart &&
                        props.invoice?.status !== InvoiceStatus.Budget && (
                            <br />
                        )}

                    {props.invoice?.kitPower && (
                        <StyledKitPowerInfo>
                            {`Potência do kit: ${props.invoice?.kitPower?.toFixed(
                                kitPowerDecimals,
                            )}kW`}
                        </StyledKitPowerInfo>
                    )}
                    <Table
                        hover
                        bordered
                        className="text-center mt-2"
                        size="sm"
                        style={{ backgroundColor: '#ffffff' }}
                    >
                        <thead>
                            <tr>
                                <StyledTh>EQUIPAMENTOS</StyledTh>
                                <StyledTh>CÓDIGO</StyledTh>
                                <StyledTh>DESCRIÇÃO</StyledTh>
                                <StyledTh>DISPONIBILIDADE</StyledTh>
                                <StyledTh>QUANTIDADE</StyledTh>
                                <StyledTh>VALOR UNITÁRIO</StyledTh>
                                <StyledTh>VALOR TOTAL</StyledTh>
                            </tr>
                        </thead>
                        <tbody>
                            {props.invoice &&
                                props.invoice?.invoiceItems
                                    ?.filter(
                                        (invocieItem: any) =>
                                            invocieItem.quantity > 0,
                                    )
                                    ?.map((item: any) => (
                                        <tr key={item.id}>
                                            <td>{item.productCategory}</td>
                                            <td>{item.productErpCode}</td>
                                            <td>{item.productName}</td>
                                            <td>
                                                <BaseAvailabilityBadge
                                                    isImmediate={
                                                        item.availability ===
                                                        ProductAvailability.Immediate
                                                    }
                                                    boardingDate={
                                                        item.availability
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <NumericInput
                                                    disabled={
                                                        props.onBudgetEditing ===
                                                        undefined
                                                            ? props.disabled
                                                            : !props.onBudgetEditing
                                                    }
                                                    tableItem={item ?? {}}
                                                    onBudgetEditing={
                                                        props.onBudgetEditing
                                                    }
                                                    invoice={cart ?? {}}
                                                    setInvoice={setCart}
                                                    item={
                                                        {
                                                            status: props
                                                                .invoice.status,
                                                            invoiceId:
                                                                item.invoiceId,
                                                            id: item.id,
                                                            productErpCode:
                                                                item.productErpCode,
                                                            productName:
                                                                item.productName,
                                                            unitValue:
                                                                item.unitValue,
                                                            isCart: false,
                                                            availability:
                                                                item?.boardingDate,
                                                        } as ISaveInvoiceItemDto
                                                    }
                                                />
                                            </td>
                                            <td>
                                                {formatCurrency(item.unitValue)}
                                            </td>
                                            <td>
                                                {formatCurrency(
                                                    item.totalValue,
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                        </tbody>
                        <tfoot>
                            <StyledTr>
                                <td className="text-right" colSpan={6}>
                                    Subtotal
                                </td>
                                <td>
                                    {formatCurrency(
                                        props.invoice?.totalInvoice,
                                    )}
                                </td>
                            </StyledTr>

                            {(props.paymentDeliveryEditing ||
                                (props.invoice?.status &&
                                    props.invoice?.status !==
                                        InvoiceStatus.Cart)) && (
                                <>
                                    <StyledTr>
                                        <td className="text-right" colSpan={6}>
                                            Comissão
                                        </td>
                                        <td>
                                            {formatCurrency(
                                                Number(
                                                    props.invoice
                                                        ?.grossCommission,
                                                ),
                                            )}
                                        </td>
                                    </StyledTr>
                                    <StyledTr>
                                        <td className="text-right" colSpan={6}>
                                            Acréscimos
                                        </td>
                                        <td>{formatCurrency(interestValue)}</td>
                                    </StyledTr>
                                </>
                            )}

                            {props.invoice?.status &&
                                props.invoice?.status !==
                                    InvoiceStatus.Cart && (
                                    <>
                                        <StyledTr>
                                            <td
                                                className="text-right"
                                                colSpan={6}
                                            >
                                                Desconto
                                            </td>
                                            <td>
                                                {formatCurrency(
                                                    props.invoice?.discountValue
                                                        ? props.invoice
                                                              ?.discountValue
                                                        : 0,
                                                )}
                                            </td>
                                        </StyledTr>
                                    </>
                                )}
                            {(props.paymentDeliveryEditing ||
                                (props.invoice?.status &&
                                    props.invoice?.status !==
                                        InvoiceStatus.Cart)) && (
                                <>
                                    <StyledTr>
                                        <td className="text-right" colSpan={6}>
                                            Total
                                        </td>
                                        <td>
                                            {formatCurrency(
                                                +props.invoice?.totalInvoice +
                                                    +props.invoice
                                                        ?.grossCommission -
                                                    +(props.invoice
                                                        ?.discountValue
                                                        ? props.invoice
                                                              ?.discountValue
                                                        : 0) +
                                                    +interestValue,
                                            )}
                                        </td>
                                    </StyledTr>
                                </>
                            )}
                        </tfoot>
                    </Table>
                </>
            )}

            <StructureCalculationModal
                show={calculationOfStructuresModal}
                onHide={() => setCalculationOfStructuresModal(false)}
                budget={props?.budget}
                invoiceId={props?.invoice?.id}
                addToInvoiceItems={props?.addToInvoiceItems}
            />

            {showConfirmation && (
                <ConfirmationDialog
                    show={showConfirmation}
                    onHide={() => setShowConfirmation(false)}
                    onConfirm={mutationClearCart.mutateAsync}
                    title="Limpar carrinho"
                    text="Deseja limpar o carrinho? As informações serão perdidas."
                    icon={NotificationIcon.Warning}
                />
            )}
        </>
    );
};

export default Cart;
