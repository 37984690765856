import { Form, Col, Modal, Row } from 'react-bootstrap';
import { ReactComponent as ReactTruckIcon } from '../../../../assets/icons/truck.svg';
import getInvoiceStatusText from '../../../../utils/getInvoiceStatusText';
import {
    StyledDivInvoiceDelivery,
    StyledSpan,
    StyledCloseButton,
} from './styles';

interface FeedbackInvoiceModalProps {
    show: boolean;
    onHide: () => void;
    selectedInvoice: any;
}

export default function FeedbackInvoiceModal({
    show,
    onHide,
    selectedInvoice,
}: FeedbackInvoiceModalProps) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Feedback do pedido
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <StyledSpan className="d-block">
                            <strong>Número do pedido: </strong>
                            {selectedInvoice.id}
                        </StyledSpan>
                        <StyledSpan className="d-block">
                            <strong>Status: </strong>
                            {getInvoiceStatusText(
                                selectedInvoice.status,
                                selectedInvoice.paymentMethod,
                            )}
                        </StyledSpan>
                        <StyledSpan className="d-block">
                            <strong>Valor total: </strong>
                            {selectedInvoice.totalInvoice?.toLocaleString(
                                'pt-br',
                                {
                                    style: 'currency',
                                    currency: 'BRL',
                                },
                            )}
                        </StyledSpan>
                    </Col>
                    <Col>
                        <StyledDivInvoiceDelivery className="float-right text-center">
                            {selectedInvoice.deliveryDeadline ? (
                                <>
                                    <strong>Prazo de entrega</strong>
                                    <div className="d-block mt-2">
                                        <ReactTruckIcon
                                            fill="#707070"
                                            width="18"
                                            height="18"
                                            className="mr-1 mt-n1"
                                        />
                                        {`${selectedInvoice.deliveryDeadline} dias úteis`}
                                    </div>
                                </>
                            ) : (
                                <strong>Prazo de entrega indisponível</strong>
                            )}
                        </StyledDivInvoiceDelivery>
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-4">
                        <Form.Group controlId="formBasicComment">
                            <Form.Label>Mensagem</Form.Label>
                            <Form.Control
                                as="textarea"
                                disabled
                                value={selectedInvoice.approvalFeedback}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <StyledCloseButton onClick={onHide}>Fechar</StyledCloseButton>
            </Modal.Footer>
        </Modal>
    );
}
