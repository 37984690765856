import { Form, Modal, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { invoicesListRoute } from '../../../../routes/config';
import InvoiceHttpService from '../../../../services/http/invoice-http';
import getSanitizedDiscount from '../../../../utils/getSanitizedDiscount';
import isFriendlyHttpError from '../../../../utils/isFriendlyHttpError';
import DeliveryDateInput from '../DeliveryDateInput';
import DiscountInput from '../DiscountInput';
import FeedbackTextArea from '../FeedbackTextArea';
import FutureSaleToggle from '../FutureSaleToggle';
import TriangulationToggle from '../TriangulationToggle';
import ValidateButton from '../ValidateButton';
import history from '../../../../services/history';
import InvoiceSummary from '../../InvoiceSummary';

interface InvoiceValidationFormProps {
    invoice: any;
    onSubmitError: () => void;
    minDeliveryDate: Date;
}

export interface InvoiceValidationFields {
    discount: string;
    deliveryDate: Date | null;
    isFutureSale: boolean;
    isTriangulation: boolean;
    feedback: string;
}

export default function InvoiceValidationForm({
    invoice,
    onSubmitError,
    minDeliveryDate,
}: InvoiceValidationFormProps) {
    const form = useForm<InvoiceValidationFields>({
        shouldUnregister: false,
        defaultValues: {
            discount: '0',
            deliveryDate: null,
            isFutureSale: false,
            isTriangulation:
                invoice.seller.canDoTriangulation &&
                invoice.customerType === 'INTEGRATOR',
            feedback: '',
        },
    });

    const formData = form.watch();

    const validateMutation = useMutation({
        mutationKey: ['validate-invoice', invoice.id],
        mutationFn: async () => {
            await InvoiceHttpService.validate(invoice.id, {
                ...formData,
                discount: getSanitizedDiscount(formData.discount),
                deliveryDate: formData.deliveryDate.toISOString(),
            });
        },
        onSuccess: () => {
            toast.success('Pedido validado com sucesso');

            history.push(invoicesListRoute.path);
        },
        onError: (error: any) => {
            onSubmitError();

            if (isFriendlyHttpError(error)) {
                toast.error(error.message as string);

                return;
            }

            toast.error('Ocorreu um erro ao validar o pedido');
        },
    });

    const onSubmit = form.handleSubmit(() => validateMutation.mutateAsync());

    return (
        <Form onSubmit={onSubmit}>
            <Modal.Header closeButton>
                <Modal.Title>Validação do pedido</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <DiscountInput
                            form={form}
                            disabled={validateMutation.isLoading}
                            maxValue={invoice.totalInvoice}
                        />
                        <DeliveryDateInput
                            form={form}
                            disabled={validateMutation.isLoading}
                            minDeliveryDate={minDeliveryDate}
                        />
                        <FutureSaleToggle
                            form={form}
                            disabled={validateMutation.isLoading}
                        />
                        <TriangulationToggle
                            form={form}
                            disabled={validateMutation.isLoading}
                            invoice={invoice}
                        />
                    </Col>
                    <Col md={6}>
                        <InvoiceSummary
                            invoice={invoice}
                            discountPercentage={
                                (getSanitizedDiscount(formData.discount) /
                                    invoice.totalInvoice) *
                                100
                            }
                            showInstallmentRow={false}
                        />
                    </Col>
                    <Col md={12}>
                        <FeedbackTextArea
                            form={form}
                            disabled={validateMutation.isLoading}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <ValidateButton isSubmitting={validateMutation.isLoading} />
            </Modal.Footer>
        </Form>
    );
}
