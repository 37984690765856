/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-fragments */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unneeded-ternary */
import { Card, Nav } from 'react-bootstrap';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { paymentNavItems } from '../../../constants/onlineStore';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import DeliveryAddress from '../DeliveryAddress';
import AdditionalData from '../AdditionalData';
import Cart from '../Cart';
import Commission from '../Commission';
import FinancingModal from '../Financing/FinancingModal';
import PaymentMethod from '../PaymentMethod';
import CustomerSelection from '../CustomerSelection';
import { useAuth } from '../../../contexts/authContext';
import userHasRoles from '../../../utils/userHasRoles';
import { Role } from '../../../constants/role';
import { Logs } from '../Logs';
import { BudgetEditLayout } from '../../BudgetEditLayout';
import { InvoiceStatus } from '../../../constants/invoiceStatus';
import InsuranceMethod from '../Insurance';
import InvoiceHttpService from '../../../services/http/invoice-http';
import Branch from '../Branch';

interface Props {
    invoice: any;
    disabled: any;
    showLogsOption?: boolean;
    tab?: number;
}

interface PaymentNavItem {
    name: string;
    step: number;
}

const PaymentAndDelivery: React.FC<Props> = ({
    invoice,
    disabled,
    showLogsOption,
    tab,
}) => {
    const location = useLocation();
    const pathName = location.pathname.split('/', 2);

    const { showFinacingModal, setShowFinacingModal, setCart } =
        useOnlineStore();

    const [paymentItem, setPaymentItem] = useState(0);

    const { user } = useAuth();
    const isIntegratorAndContributor = userHasRoles(user, [
        Role.Integrator,
        Role.Contributor,
        Role.Manager,
    ]);

    const isAllowedToAlterBranch = userHasRoles(user, [
        Role.Administrator,
        Role.CommercialSupervisor,
    ]);

    const getNavItems = () => {
        let filteredNavItems = paymentNavItems;

        if (isIntegratorAndContributor || !showLogsOption) {
            filteredNavItems = filteredNavItems.filter(
                (nav: PaymentNavItem) => nav.name !== 'Logs',
            );
        }

        if (
            !isAllowedToAlterBranch ||
            invoice.status === InvoiceStatus.Cart ||
            invoice.status === InvoiceStatus.Budget ||
            invoice.status === InvoiceStatus.BudgetExpired
        ) {
            filteredNavItems = filteredNavItems.filter(
                (nav: PaymentNavItem) => nav.name !== 'Filial',
            );
        }

        return filteredNavItems;
    };

    let navItems: PaymentNavItem[] = getNavItems();

    const isOnlineStore: boolean =
        pathName[1] === 'kits-prontos' ||
        pathName[1] === 'monte-seu-gerador' ||
        pathName[1] === 'sales'
            ? true
            : false;

    function replaceResumeByPaymentMethodNavItems(
        navigationItems: PaymentNavItem[],
    ): PaymentNavItem[] {
        const navItemsWithPaymentMethod: PaymentNavItem[] = [
            {
                name: 'Método de pagamento',
                step: 0,
            },
        ];

        navigationItems = navigationItems.filter(
            (nav) =>
                nav.name !== 'Método de pagamento' && nav.name !== 'Resumo',
        );

        return navItemsWithPaymentMethod.concat(navigationItems);
    }

    if (isOnlineStore) {
        navItems = replaceResumeByPaymentMethodNavItems(navItems);
    }

    const shouldUpdate = useRef(true);

    useEffect(() => {
        const updateInvoiceInsurance = async () => {
            const response = await InvoiceHttpService.updateInsurance(
                invoice.id,
            );

            setCart((previousCart: any) => ({
                ...previousCart,
                insurance: response.data,
            }));
        };

        if (
            invoice &&
            (invoice.status === InvoiceStatus.Cart ||
                invoice.status === InvoiceStatus.Budget ||
                invoice.status === InvoiceStatus.Invoice ||
                invoice.status === InvoiceStatus.InvoiceValidated) &&
            shouldUpdate.current
        ) {
            updateInvoiceInsurance();

            shouldUpdate.current = false;
        }
    }, [invoice, setCart]);

    useEffect(() => {
        setPaymentItem(tab ?? 0);
    }, [invoice.id, tab]);

    return (
        <React.Fragment>
            <Card
                style={{
                    width: '100%',
                    backgroundColor: '#fafafa',
                    border: '1px solid #f2f2f2',
                }}
            >
                <Card.Body>
                    <Nav activeKey={paymentItem} className={'mt-n3'}>
                        {navItems.map((item, index: any) => (
                            <Nav.Item key={index}>
                                <Nav.Link
                                    className={'p-3'}
                                    style={
                                        paymentItem === item.step
                                            ? {
                                                  borderBottom:
                                                      '2px solid #F2994A',

                                                  color: '#F2994A',
                                                  fontWeight: 600,
                                              }
                                            : {
                                                  borderBottom:
                                                      '1px solid #F2F2F2',

                                                  color: '#707070',
                                              }
                                    }
                                    eventKey={item.name}
                                    onClick={() => {
                                        setPaymentItem(item.step);
                                    }}
                                >
                                    {item.name}
                                </Nav.Link>
                            </Nav.Item>
                        ))}
                    </Nav>
                    {paymentItem === 0 && (
                        <>
                            {invoice.status === InvoiceStatus.Budget &&
                                pathName[1] === 'orcamentos' && (
                                    <BudgetEditLayout
                                        disabled={disabled}
                                        invoice={invoice ?? {}}
                                    />
                                )}
                            {invoice.status !== InvoiceStatus.Budget && (
                                <>
                                    {isOnlineStore && (
                                        <PaymentMethod
                                            disabled={disabled}
                                            invoice={invoice ?? {}}
                                        />
                                    )}
                                    <Cart
                                        disabled={disabled}
                                        paymentDeliveryEditing
                                        invoice={invoice ?? {}}
                                    />
                                </>
                            )}
                        </>
                    )}
                    {paymentItem === 1 && (
                        <CustomerSelection
                            disabled={disabled}
                            invoice={invoice ?? {}}
                        />
                    )}
                    {paymentItem === 2 && (
                        <DeliveryAddress
                            disabled={disabled}
                            invoice={invoice ?? {}}
                        />
                    )}
                    {paymentItem === 3 && !isOnlineStore && (
                        <PaymentMethod
                            disabled={disabled}
                            invoice={invoice ?? {}}
                        />
                    )}
                    {paymentItem === 4 && (
                        <Commission
                            disabled={disabled}
                            invoice={invoice ?? {}}
                        />
                    )}
                    {paymentItem === 5 && (
                        <AdditionalData
                            disabled={disabled}
                            invoice={invoice ?? {}}
                        />
                    )}
                    {paymentItem === 6 && (
                        <InsuranceMethod invoice={invoice ?? {}} />
                    )}
                    {paymentItem === 7 && <Branch invoice={invoice ?? {}} />}
                    {paymentItem === 8 && <Logs invoice={invoice} />}
                    <FinancingModal
                        invoice={invoice ?? {}}
                        disabled={disabled}
                        onHide={() => {
                            setShowFinacingModal(false);
                        }}
                        show={showFinacingModal}
                    />
                </Card.Body>
            </Card>
        </React.Fragment>
    );
};

PaymentAndDelivery.propTypes = {
    invoice: PropTypes.object,
    disabled: PropTypes.bool,
    showLogsOption: PropTypes.bool,
    tab: PropTypes.number,
};

export default PaymentAndDelivery;
