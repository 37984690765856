import { Table } from 'react-bootstrap';
import { PaymentMethod } from '../../../constants/onlineStore';
import { formatCurrency } from '../../../utils/strings';
import StyledBoxDelivery from './styles';

interface InvoiceSummaryProps {
    invoice: any;
    discountPercentage?: number;
    showInstallmentRow?: boolean;
}

export default function InvoiceSummary({
    invoice,
    discountPercentage,
    showInstallmentRow = true,
}: InvoiceSummaryProps) {
    const commission = Number(invoice.grossCommission);

    const subtotal = Number(invoice.totalInvoice);

    const percentage = discountPercentage || Number(invoice.percentage) || 0;

    const discount = (subtotal * percentage) / 100;

    const interest =
        (subtotal + commission - discount) *
        (Number(invoice.interestPercentage) / 100);

    const total = subtotal + commission + interest - discount;

    const installmentPaymentMethods = [
        PaymentMethod.CreditCard,
        PaymentMethod.CreditCardAndBarcode,
    ];

    return (
        <>
            <h5 className="mt-4">
                <strong>Resumo do pedido</strong>
            </h5>
            <Table size="sm" className="mt-2">
                <tbody>
                    <tr>
                        <td className="text-left">subtotal</td>
                        <td className="text-right">
                            {formatCurrency(subtotal)}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-left">Comissão</td>
                        <td className="text-right">
                            {formatCurrency(commission)}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-left">Acréscimos</td>
                        <td className="text-right">
                            {formatCurrency(interest)}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-left">Desconto</td>
                        <td className="text-right">
                            {formatCurrency(discount)}
                        </td>
                    </tr>
                    {showInstallmentRow &&
                        installmentPaymentMethods.includes(
                            invoice.paymentMethod,
                        ) && (
                            <tr>
                                <td>Parcelamento</td>
                                <td className="text-right">
                                    <strong>
                                        {invoice.cardMaxInstallmentNumber}x{' '}
                                    </strong>
                                </td>
                            </tr>
                        )}
                    <tr>
                        <td className="text-left">Total</td>
                        <td className="text-right">
                            <strong>{formatCurrency(total)}</strong>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <StyledBoxDelivery className="text-center">
                <p>
                    <strong>
                        {invoice.deliveryDeadline
                            ? `Previsão de entrega: ${invoice.deliveryDeadline} dias úteis`
                            : 'Prazo de entrega indisponível'}
                    </strong>
                </p>
            </StyledBoxDelivery>
        </>
    );
}
