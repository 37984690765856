import React from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useOnlineStore } from '../../../../contexts/onlineStoreContext';
import InvoiceHttpService from '../../../../services/http/invoice-http';
import { formatCurrency } from '../../../../utils/strings';
import getTotalInvoice from '../../../../utils/getTotalInvoice';
import { InterestRatesProps } from '..';

interface Props {
    invoice: any;
    disabled: boolean;
    maxInstallments: number;
    interestRates: InterestRatesProps[];
}

const CreditCard: React.FC<Props> = ({
    invoice,
    disabled,
    maxInstallments,
    interestRates,
}) => {
    const { setCart } = useOnlineStore();

    const getAdditionalText = (installment: number) => {
        if (installment === invoice.cardMaxInstallmentNumber) {
            return `de ${formatCurrency(
                getTotalInvoice(invoice) / installment,
            )} ${
                +invoice.interestPercentage > 0 ? 'com' : 'sem'
            } juros (total ${formatCurrency(getTotalInvoice(invoice))})`;
        }

        let total =
            invoice.totalInvoice +
            +invoice.grossCommission -
            (invoice.discountValue || 0);
        let interest = 0;
        let hasInterest = 'sem';

        const installmentInterest = interestRates.find(
            (ir) => ir.installment === installment,
        );

        if (installmentInterest && +installmentInterest.percentage > 0) {
            interest = (total * +installmentInterest.percentage) / 100;
            hasInterest = 'com';
        }

        total += interest;

        const installmentValue = total / installment;

        return `de ${formatCurrency(
            installmentValue,
        )} ${hasInterest} juros (total ${formatCurrency(total)})`;
    };

    const handleInstallmentChange = async (event: any) => {
        const updatedInvoice = { ...invoice };

        updatedInvoice.cardMaxInstallmentNumber = Number(event.target.value);

        try {
            const response = await InvoiceHttpService.update(updatedInvoice);

            setCart(response.data);
        } catch (error) {
            toast.error('Erro ao salvar parcelamento');
        }
    };

    return (
        <>
            <Form
                style={{
                    width: '100%',
                }}
            >
                <Form.Group
                    className="pl-4 pr-4 mb-5 mt-3"
                    controlId="formBasicName"
                >
                    <Form.Label>Parcelamento</Form.Label>
                    <Form.Control
                        disabled={disabled}
                        onChange={handleInstallmentChange}
                        name="installments"
                        type="text"
                        value={invoice?.cardMaxInstallmentNumber}
                        as="select"
                        placeholder="Selecione..."
                    >
                        {invoice.totalInvoice > 0 && (
                            <>
                                {[
                                    ...Array.from(
                                        { length: maxInstallments },
                                        (_, i) => i + 1,
                                    ),
                                ].map((installment: number, index: number) => (
                                    <option key={index} value={installment}>
                                        {`${installment}x ${getAdditionalText(
                                            installment,
                                        )}`}
                                    </option>
                                ))}
                            </>
                        )}
                    </Form.Control>
                </Form.Group>
            </Form>
        </>
    );
};

export default CreditCard;
