/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledDivInvoiceDelivery = styled.div`
    background: #f2f2f2;
    width: 140px;
    padding: 4px;
    float: right;
    font-size: 12px;
`;
