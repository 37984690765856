/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable radix */
/* eslint-disable consistent-return */
/* eslint-disable react/require-default-props */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import Cart from '../OnlineStore/Cart';
import { Icon } from './styles';
import ProductsList from './ProductsList';
import InvoiceHttpService from '../../services/http/invoice-http';
import { useOnlineStore } from '../../contexts/onlineStoreContext';
import ProductHttpService from '../../services/http/product-http';
import { PaymentMethod } from '../../constants/paymentMethod';
import ConfirmationDialog from '../ConfirmationDialog';
import { NotificationIcon } from '../../constants/notificationIcon';

interface Props {
    disabled: boolean;
    invoice?: any;
}

export function BudgetEditLayout({ disabled, invoice }: Props) {
    const { setCart } = useOnlineStore();
    const [step, setStep] = useState(3);
    const [enableEdit, setEnableEdit] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);

    const [showConfirmation, setShowConfirmation] = useState(false);

    async function loadBudget() {
        setCart();

        const response: any = await InvoiceHttpService.show(invoice.id);

        setCart({
            ...invoice,
            ...response.data.data,
        });

        return response.data.data;
    }

    async function refreshItemsPrice() {
        try {
            const productsIds = invoice.invoiceItems
                .map((item: any) => item.productErpCode)
                .toString();

            const params = {
                sellerId: invoice.seller ? invoice.seller.erpCode : '',
                groupId: '',
                term: '',
                type: invoice.type,
                invoiceId: invoice.id,
                obsolete: 'S',
                productsIds,
            };

            const { data } = await ProductHttpService.index(params);

            if (
                parseInt(data.data.meta.TOTAL) !== invoice.invoiceItems.length
            ) {
                toast.error('Não foi possível atualizar os valores.');
                return handleStopEditing();
            }

            let totalValueItems = 0;

            const newInvoiceItems = invoice.invoiceItems.map(
                (invoiceItem: any) => {
                    const product = data.data.products.find(
                        (item: any) =>
                            item.B1_COD === invoiceItem.productErpCode,
                    );

                    const [unitValue, totalValue] = [
                        parseFloat(product.VLR_TOT),
                        parseFloat(product.VLR_TOT) * invoiceItem.quantity,
                    ];

                    totalValueItems += totalValue;

                    return {
                        ...invoiceItem,
                        isCart: false,
                        unitValue,
                        totalValue,
                    };
                },
            );

            setCart({
                ...invoice,
                invoiceItems: newInvoiceItems,
                totalInvoice: totalValueItems,
            });
        } catch (error) {
            console.log(error);
        }
    }

    const handleStopEditing = () => {
        loadBudget();
        setStep(3);
        setEnableEdit(false);
    };

    const handleBudgetSave = async () => {
        try {
            setLoadingButton(true);

            if (invoice?.type === 'MP') {
                const haveInverter = invoice?.invoiceItems.some(
                    (item: any) =>
                        ['1010'].includes(
                            item.productErpCode.substring(0, 4),
                        ) && parseInt(item.quantity) > 0,
                );
                const havePanel = invoice?.invoiceItems.some(
                    (item: any) =>
                        ['1001'].includes(
                            item.productErpCode.substring(0, 4),
                        ) && parseInt(item.quantity) > 0,
                );

                if (!haveInverter || !havePanel) {
                    toast.error(
                        'Para que sua compra seja autorizada, é necessário adicionar no mínimo 1 inversor e 1 Painel solar em seu pedido.',
                    );
                    return true;
                }
            }

            await InvoiceHttpService.budgetItems(
                invoice.id,
                invoice.invoiceItems,
            );

            toast.success(`Orçamento atualizado com sucesso!`);

            setLoadingButton(true);
            handleStopEditing();
        } catch (error) {
            setLoadingButton(true);

            toast.error(`Houve algum erro ao editar este orçamento!`);
        }
    };

    const checkDisabled = () => {
        const haveAllItemsZeroed = invoice?.invoiceItems.some(
            (item: any) => parseInt(item.quantity) > 0,
        );

        if (
            !invoice?.sellerId ||
            !invoice?.customerDocument ||
            !invoice?.deliveryAddressCep ||
            !invoice?.deliveryAddressDescription ||
            !invoice?.deliveryAddressErpState ||
            !invoice?.deliveryAddressErpCity ||
            !invoice?.deliveryAddressNeighborhood ||
            !invoice?.deliveryAddressCep ||
            !invoice?.paymentMethod ||
            !haveAllItemsZeroed ||
            (invoice?.paymentMethod === PaymentMethod.Financing &&
                !invoice?.financingBank) ||
            (invoice?.paymentMethod === PaymentMethod.Financing &&
                invoice?.financingBank === 'BV' &&
                !invoice?.financing)
        )
            return true;

        return false;
    };

    const addToInvoiceItems = ({
        invoiceItems,
        totalInvoice,
    }: {
        invoiceItems: any[];
        totalInvoice: number;
    }) => {
        if (!invoice || !invoice.invoiceItems) {
            return;
        }

        invoice.invoiceItems = invoiceItems;
        invoice.totalInvoice = totalInvoice;
    };

    const enableEditing = async () => {
        await refreshItemsPrice();
        setEnableEdit(true);
    };

    useEffect(() => {
        if (enableEdit) {
            setStep(2);
        }
    }, [enableEdit]);

    return (
        <>
            <Col className={'mt-4'}>
                <Row
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Col>
                        <Row
                            onClick={() => setShowConfirmation(true)}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                width: 278,
                            }}
                            title={'Clique para editar este orçamento!'}
                        >
                            <Icon>
                                <EditIcon fill="#fff" />
                            </Icon>
                            <Col>
                                <h5 className="mb-0">Editar</h5>
                                <p className="mb-0">
                                    Clique para editar o orçamento
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    {enableEdit && (
                        <Col>
                            <Row
                                style={{
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button
                                    onClick={handleStopEditing}
                                    style={{ width: '110px' }}
                                    variant={'outline-primary'}
                                    className={'mr-2'}
                                >
                                    Voltar
                                </Button>
                                <Button
                                    disabled={checkDisabled()}
                                    style={{ width: '110px' }}
                                    onClick={handleBudgetSave}
                                >
                                    {loadingButton ? (
                                        <Spinner animation={'border'} />
                                    ) : (
                                        'Salvar'
                                    )}
                                </Button>
                            </Row>
                        </Col>
                    )}
                </Row>
            </Col>
            <hr />
            {step === 2 && enableEdit && (
                <ProductsList invoice={invoice} step={step} setStep={setStep} />
            )}
            {step === 3 && (
                <Cart
                    onBudgetEditing={enableEdit}
                    budget
                    disabled={disabled}
                    invoice={invoice}
                    step={step}
                    setStep={setStep}
                    addToInvoiceItems={addToInvoiceItems}
                />
            )}

            {showConfirmation && (
                <ConfirmationDialog
                    show={showConfirmation}
                    onHide={() => setShowConfirmation(false)}
                    onConfirm={enableEditing}
                    title="Editar orçamento"
                    text={`Deseja editar o orçamento?\n(sujeito a atualização dos valores)`}
                    icon={NotificationIcon.Info}
                />
            )}
        </>
    );
}
