/* eslint-disable prefer-const */
/* eslint-disable prefer-destructuring */
export default function getTotalInvoice(invoice: any, percentage?: any) {
    let discountPercentage = invoice.percentage || percentage;
    let totalInvoice = invoice.totalInvoice;
    let interestPercentage = invoice.interestPercentage;

    if (discountPercentage) {
        totalInvoice -= +invoice.totalInvoice * (+discountPercentage / 100);
    }

    if (invoice.grossCommission) {
        totalInvoice += +invoice.grossCommission;
    }

    if (interestPercentage) {
        totalInvoice += +(
            totalInvoice *
            (Number(invoice.interestPercentage) / 100)
        );
    }

    return totalInvoice;
}
