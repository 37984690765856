import { Col, Row } from 'react-bootstrap';
import { getPaymentMethodText } from '../../../../utils/getPaymentMethodText';
import { formatCurrency } from '../../../../utils/strings';
import { StyledData, StyledDataHeader, StyledSectionTitle } from '../styles';

interface PaymentData {
    method: string;
    total: string;
    commission: number;
    discountPercentage: string;
    interestPercentage: string;
}

interface Props {
    paymentData: PaymentData;
}

export default function InvoicePaymentData({ paymentData }: Props) {
    const subtotal = Number(paymentData.total);
    const { commission } = paymentData;
    const discount = subtotal * (Number(paymentData.discountPercentage) / 100);
    const interest =
        (subtotal + commission - discount) *
        (Number(paymentData.interestPercentage) / 100);
    const total = subtotal + commission + interest - discount;

    return (
        <>
            <StyledSectionTitle>Pagamento</StyledSectionTitle>

            <Row>
                <Col>
                    <StyledDataHeader>Método de pagamento</StyledDataHeader>
                    <StyledData>
                        {getPaymentMethodText(paymentData.method) || '-'}
                    </StyledData>
                </Col>
                <Col>
                    <StyledDataHeader>Total</StyledDataHeader>
                    <StyledData>{formatCurrency(total)}</StyledData>
                </Col>
            </Row>

            <Row>
                <Col>
                    <StyledDataHeader>Subtotal</StyledDataHeader>
                    <StyledData>{formatCurrency(subtotal)}</StyledData>
                </Col>
                <Col>
                    <StyledDataHeader>Comissão</StyledDataHeader>
                    <StyledData>{formatCurrency(commission)}</StyledData>
                </Col>
            </Row>

            <Row>
                <Col>
                    <StyledDataHeader>Acréscimos</StyledDataHeader>
                    <StyledData>{formatCurrency(interest)}</StyledData>
                </Col>
                <Col>
                    <StyledDataHeader>Desconto</StyledDataHeader>
                    <StyledData>{formatCurrency(discount)}</StyledData>
                </Col>
            </Row>
        </>
    );
}
