import { Col, Modal, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import {
    StyledCloseButton,
    StyledDivInvoiceDelivery,
    StyledSpan,
    StyledLink,
    StyledRow,
} from './styles';

import InvoiceHttpService from '../../../../services/http/invoice-http';
import { ReactComponent as ReactTruckIcon } from '../../../../assets/icons/truck.svg';
import { ATTACHMENTS_SRC_URL } from '../../../../config/webservice';
import isFriendlyHttpError from '../../../../utils/isFriendlyHttpError';
import getInvoiceStatusText from '../../../../utils/getInvoiceStatusText';

interface ListAttachmentsModalProps {
    show: boolean;
    onHide: () => void;
    invoiceId: number;
}

export default function ListAttachmentsModal({
    show,
    onHide,
    invoiceId,
}: ListAttachmentsModalProps) {
    const invoiceQuery = useQuery({
        queryKey: ['invoice', invoiceId],
        queryFn: async () => {
            const response = await InvoiceHttpService.show(
                invoiceId.toString(),
            );

            return response.data.data;
        },
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
                return;
            }

            toast.error('Ocorreu um erro ao carregar os comprovante do pedido');
            onHide();
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
    });

    return (
        <Modal
            onHide={onHide}
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Comprovantes anexados
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {invoiceQuery.isLoading || invoiceQuery.isFetching ? (
                    <Row>
                        <Col md={12} className="text-center">
                            <Spinner animation="border" />
                        </Col>
                    </Row>
                ) : (
                    <>
                        <Row>
                            <Col>
                                <StyledSpan className="d-block">
                                    <strong>{`Número do pedido: ${invoiceId}`}</strong>
                                </StyledSpan>
                                <StyledSpan className="d-block">
                                    <strong>Status: </strong>
                                    {getInvoiceStatusText(
                                        invoiceQuery.data?.status,
                                    )}
                                </StyledSpan>
                                <StyledSpan className="d-block">
                                    <strong>Valor total: </strong>
                                    {invoiceQuery.data?.totalInvoice &&
                                        invoiceQuery.data?.totalInvoice.toLocaleString(
                                            'pt-br',
                                            {
                                                style: 'currency',
                                                currency: 'BRL',
                                            },
                                        )}
                                </StyledSpan>
                            </Col>

                            <Col>
                                <StyledDivInvoiceDelivery className="float-right text-center">
                                    {invoiceQuery.data?.deliveryDeadline ? (
                                        <>
                                            <strong>Prazo de entrega</strong>
                                            <div className="d-block mt-2">
                                                <ReactTruckIcon
                                                    fill="#707070"
                                                    width="18"
                                                    height="18"
                                                    className="mr-1 mt-n1"
                                                />
                                                {
                                                    invoiceQuery.data
                                                        ?.deliveryDeadline
                                                }{' '}
                                                dias úteis
                                            </div>
                                        </>
                                    ) : (
                                        <strong>
                                            Prazo de entrega indisponível
                                        </strong>
                                    )}
                                </StyledDivInvoiceDelivery>
                            </Col>
                        </Row>

                        <div
                            style={{ height: 'auto', maxHeight: '300px' }}
                            className="mt-3 light-scrollbar"
                        >
                            {invoiceQuery.data?.invoiceAttachments?.map(
                                (file: any) => (
                                    <StyledRow
                                        key={file.id}
                                        className="p-3 mb-2"
                                    >
                                        <Col>
                                            <StyledLink
                                                href={`${ATTACHMENTS_SRC_URL}/${file?.filename}`}
                                                className="mt-2"
                                                key={file?.filename}
                                            >
                                                {file?.filename.substring(
                                                    file?.filename.indexOf(
                                                        '_',
                                                    ) + 1,
                                                )}
                                            </StyledLink>
                                        </Col>
                                    </StyledRow>
                                ),
                            )}
                        </div>
                    </>
                )}
            </Modal.Body>

            <Modal.Footer>
                <StyledCloseButton onClick={onHide}>Fechar</StyledCloseButton>
            </Modal.Footer>
        </Modal>
    );
}
