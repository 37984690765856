/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
import { useState, createContext, useContext } from 'react';
import { InvoiceStatus } from '../constants/invoiceStatus';
import InvoiceHttpService from '../services/http/invoice-http';
import Brand from '../shared/interfaces/brand.interface';

const OnlineStoreContext = createContext<any>({});
const useOnlineStore = () => useContext(OnlineStoreContext);

function OnlineStoreProvider(props: any) {
    const [brands, setBrands] = useState<Brand[]>([]);
    const [brandId, setBrandId] = useState<string>('');
    const [viewType, setViewType] = useState('card');
    const [productType, setProductType] = useState('');
    const [productId, setProductId] = useState('');
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [groupId, setGroupId] = useState('1001');
    const [groups, setGroups] = useState([]);
    const [step, setStep] = useState(2);
    const [totalItems, setTotalItems] = useState(0);
    const [cart, setCart] = useState({} as any);
    const [reload, setReload] = useState({});
    const [reloadCart, setReloadCart] = useState(false);
    const [showFinacingModal, setShowFinacingModal] = useState(false);

    const [term, setTerm] = useState('');
    const [tab, setTab] = useState('tab-1');

    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [sideCartVisible, setSideCartVisible] = useState(false);

    const [selectedProductId, setSelectedProductId] = useState('');
    const [modifiyngProductId, setModifiyngProductId] = useState('');
    const [loading, setLoading] = useState(false);

    const newOption = () => ({
        power: '',
        moduleId: '',
        roofTypeId: '',
        inverterId: '',
        inverterBrandId: '',
    });

    const [option, setOption] = useState(newOption());

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async function loadCart(loadCategories?: Boolean): Promise<void> {
        try {
            const resp = await InvoiceHttpService.showByUserIdAndStatus({
                status: InvoiceStatus.Cart,
            });

            setProductType(resp.data.data.type);
            setCart(resp.data.data);

            return resp.data.data;
        } catch (error) {
            setCart({});
        }
    }

    return (
        <OnlineStoreContext.Provider
            value={{
                brands,
                setBrands,
                brandId,
                setBrandId,
                viewType,
                setViewType,
                productId,
                setProductId,
                products,
                setProducts,
                selectedProduct,
                setSelectedProduct,
                groupId,
                setGroupId,
                productType,
                setProductType,
                groups,
                setGroups,
                step,
                setStep,
                totalItems,
                setTotalItems,
                cart,
                setCart,
                reload,
                setReload,
                reloadCart,
                setReloadCart,
                showDetailsModal,
                setShowDetailsModal,
                selectedProductId,
                setSelectedProductId,
                sideCartVisible,
                setSideCartVisible,
                showFinacingModal,
                setShowFinacingModal,
                modifiyngProductId,
                setModifiyngProductId,
                loadCart,
                option,
                setOption,
                newOption,
                loading,
                setLoading,
                term,
                setTerm,
                tab,
                setTab,
            }}
            {...props}
        />
    );
}

export { OnlineStoreProvider, useOnlineStore };
