export default function normalizeString(str: string) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function formatCurrency(str: any) {
    return str?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
    });
}

export function formatToQueryParam(str: any) {
    return str.replace(/[*+\\()?]/, '');
}
